import {
  Input as NextUIInput,
  InputProps as NextUIInputProps,
} from "@nextui-org/react";
import { forwardRef } from "react";

type Color = Extract<NextUIInputProps["color"], "default">;
type LabelPlacement = Extract<NextUIInputProps["labelPlacement"], "outside">;
type Variant = Extract<NextUIInputProps["variant"], "bordered">;

export type InputProps = Omit<
  NextUIInputProps,
  "color" | "labelPlacement" | "variant"
> & {
  color?: Color;
  labelPlacement?: LabelPlacement;
  variant?: Variant;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <NextUIInput
      ref={ref}
      classNames={{
        base: ["opacity-1"],
        inputWrapper: [
          "rounded-lg ring-1 ring-inset ring-gray-500 border-0 data-[disabled=true]:ring-gray-300",
          "data-[hover=true]:ring-gray-600",
          "group-data-[focus=true]:ring-primary group-data-[focus=true]:ring-2",
          "data-[invalid=true]:ring-danger group-data-[invalid=true]:text-danger",
          "group-data-[disabled=true]:bg-gray-200 group-data-[disabled=true]:text-gray-disabled",
        ],
        input: [
          "placeholder:text-gray-600",
          "data-[has-start-content=true]:ps-2 data-[has-end-content=true]:pe-2",
          "group-data-[invalid=true]:placeholder:text-danger",
        ],
        label: ["!text-base-900 font-semibold"],
        innerWrapper: ["[&>svg]:w-5 [&>svg]:!stroke-sm"],
      }}
      {...props}
      variant="bordered"
      labelPlacement="outside"
      color="default"
    />
  );
});

Input.displayName = "RecareUI.Input";
