import { EyeIcon, EyeOffIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import { Input, InputProps } from "./input";

export type PasswordInputProps = Omit<
  InputProps,
  "endContent" | "startContent" | "type"
>;

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  (props, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    return (
      <Input
        ref={ref}
        {...props}
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleVisibility}
            aria-label="toggle password visibility"
          >
            {isVisible ? (
              <EyeIcon
                className="pointer-events-none text-md text-default-900"
                size={20}
              />
            ) : (
              <EyeOffIcon
                className="pointer-events-none text-md text-default-900 group-data-[disabled=true]:text-base-disabled"
                size={20}
              />
            )}
          </button>
        }
        type={isVisible ? "text" : "password"}
      />
    );
  },
);

PasswordInput.displayName = "RecareUI.PasswordInput";
